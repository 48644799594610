import CryptoJS from "crypto-js";

export const decrypt = (data) => {
  const inputData = Buffer.from(data, "base64");

  const decryptedOutput = CryptoJS.AES.decrypt(
    {
      ciphertext: CryptoJS.lib.WordArray.create(inputData.slice(16)),
    },
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_KEY),
    {
      mode: CryptoJS.mode.CBC,
      iv: CryptoJS.enc.Utf8.parse(inputData.slice(0, 16).toString()),
    }
  );
  return JSON.parse(decryptedOutput.toString(CryptoJS.enc.Utf8));
};

export const encrypt = (data) => {
  const iv = CryptoJS.lib.WordArray.random(8).toString();
  const encrypted = CryptoJS.AES.encrypt(
    `${iv}${JSON.stringify(data)}`,
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET_KEY),
    {
      mode: CryptoJS.mode.CBC,
      iv: CryptoJS.enc.Utf8.parse(iv),
    }
  );
  return encrypted.toString();
};
