import React from "react";

import NotFoundPage from "pages/404Page";
import RenderRoutes from "components/renderRoutes";

const LayoutComponent = React.lazy(() => import("layout"));
const DashboardComponent = React.lazy(() => import("pages/dashboard"));

const AuthLoginComponent = React.lazy(() => import("pages/auth/login"));
const AuthRegisterComponent = React.lazy(() => import("pages/auth/register"));
const AuthForgotPasswordComponent = React.lazy(() =>
  import("pages/auth/password/forgotPassword")
);
const AuthResetPasswordComponent = React.lazy(() =>
  import("pages/auth/password/resetPassword")
);
const AuthVerifyEmailComponent = React.lazy(() =>
  import("pages/auth/verifyEmail")
);

const FaqComponent = React.lazy(() => import("pages/faq"));
const SettingsComponent = React.lazy(() => import("pages/settings"));

const CampaignListComponent = React.lazy(() => import("pages/campaign/list"));
const CampaignDetailComponent = React.lazy(() =>
  import("pages/campaign/detail")
);
const CampaignBasicDetailComponent = React.lazy(() =>
  import("pages/campaign/createOrEdit/basicDetails")
);
const CampaignAdDetailComponent = React.lazy(() =>
  import("pages/campaign/createOrEdit/adDetails")
);
const CampaignAdConfirmationComponent = React.lazy(() =>
  import("pages/campaign/createOrEdit/confirmation")
);

export const INDEX_ROUTE = "/";
export const SIGNUP_ROUTE = "/auth/signup/";
export const FORGOT_PASSWORD_ROUTE = "/auth/forgot/";
export const VERIFY_EMAIL_ROUTE = "/auth/verify_email/:token/";
export const RESET_PASSWORD_ROUTE = "/auth/reset_password/:token/";

export const FAQ_ROUTE = "/faq/";
export const SETTINGS_ROUTE = "/settings/";

export const CAMPAIGN_LIST_ROUTE = "/campaigns/";

export const NEW_CAMPAIGN_ROUTE = "/campaign/new-campaign/";
export const CAMPAIGN_DETAIL_ROUTE = "/campaign/:campaignId/";
export const CAMPAIGN_EDIT_ROUTE = "/campaign/:campaignId/edit/";
export const NEW_CAMPAIGN_AD_ROUTE = "/campaign/:campaignId/ad/create/";
export const CAMPAIGN_AD_EDIT_ROUTE = "/campaign/:campaignId/ad/:adId/";
export const CAMPAIGN_AD_CONFIRMATION_ROUTE =
  "/campaign/:campaignId/ad/:adId/confirmation/";

export const getCampaignDetailRoute = (campaignId) => {
  return CAMPAIGN_DETAIL_ROUTE.replace(":campaignId", campaignId);
};

export const getCampaignEditRoute = (campaignId) => {
  return CAMPAIGN_EDIT_ROUTE.replace(":campaignId", campaignId);
};

export const getCampaignAdCreateRoute = (campaignId) => {
  return NEW_CAMPAIGN_AD_ROUTE.replace(":campaignId", campaignId);
};

export const getCampaignAdEditRoute = (campaignId, adId) => {
  return CAMPAIGN_AD_EDIT_ROUTE.replace(":campaignId", campaignId).replace(
    ":adId",
    adId
  );
};

export const getCampaignAdConfirmationRoute = (campaignId, adId) => {
  return CAMPAIGN_AD_CONFIRMATION_ROUTE.replace(
    ":campaignId",
    campaignId
  ).replace(":adId", adId);
};

export const CAMPAIGN_TYPE_ROUTE = "/campaign/:campaignId/type/";
export const CAMPAIGN_COST_ROUTE = "/campaign/:campaignId/cost/";

export const getCampaignSelectionRoute = (campaignId) => {
  return CAMPAIGN_TYPE_ROUTE.replace(":campaignId", campaignId);
};

export const getCampaignCostRoute = (campaignId) => {
  return CAMPAIGN_COST_ROUTE.replace(":campaignId", campaignId);
};

export const NON_LOGIN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: RenderRoutes,
    routes: [
      {
        exact: true,
        name: "login",
        path: INDEX_ROUTE,
        component: AuthLoginComponent,
      },
      {
        exact: true,
        name: "register",
        path: SIGNUP_ROUTE,
        component: AuthRegisterComponent,
      },
      {
        exact: true,
        name: "forgot-password",
        path: FORGOT_PASSWORD_ROUTE,
        component: AuthForgotPasswordComponent,
      },
      {
        exact: true,
        name: "reset-password",
        path: RESET_PASSWORD_ROUTE,
        component: AuthResetPasswordComponent,
      },
      {
        exact: true,
        name: "verify-email",
        path: VERIFY_EMAIL_ROUTE,
        component: AuthVerifyEmailComponent,
      },
    ],
  },
];

export const LOGGED_IN_ROUTES = [
  {
    path: "/",
    name: "index",
    component: LayoutComponent,
    routes: [
      {
        exact: true,
        name: "welcome",
        path: INDEX_ROUTE,
        component: DashboardComponent,
      },
      {
        exact: true,
        name: "faq",
        path: FAQ_ROUTE,
        component: FaqComponent,
      },
      {
        exact: true,
        name: "settings",
        path: SETTINGS_ROUTE,
        component: SettingsComponent,
      },
      {
        exact: true,
        name: "campaign-list",
        path: CAMPAIGN_LIST_ROUTE,
        component: CampaignListComponent,
      },
      {
        exact: true,
        name: "new-campaign",
        path: NEW_CAMPAIGN_ROUTE,
        component: CampaignBasicDetailComponent,
      },
      {
        exact: true,
        name: "campaign-edit",
        path: CAMPAIGN_EDIT_ROUTE,
        component: CampaignBasicDetailComponent,
      },
      {
        exact: true,
        name: "new-campaign-ad",
        path: NEW_CAMPAIGN_AD_ROUTE,
        component: CampaignAdDetailComponent,
      },
      {
        exact: true,
        name: "edit-campaign-ad",
        path: CAMPAIGN_AD_EDIT_ROUTE,
        component: CampaignAdDetailComponent,
      },
      {
        exact: true,
        name: "new-campaign-ad-confirmation",
        path: CAMPAIGN_AD_CONFIRMATION_ROUTE,
        component: CampaignAdConfirmationComponent,
      },
      {
        exact: true,
        name: "campaign-detail",
        path: CAMPAIGN_DETAIL_ROUTE,
        component: CampaignDetailComponent,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  {
    title: "Dashboard",
    url: INDEX_ROUTE,
  },
  {
    title: "Campaigns",
    url: CAMPAIGN_LIST_ROUTE,
  },
  {
    title: "Help",
    url: FAQ_ROUTE,
  },
  {
    title: "Settings",
    url: SETTINGS_ROUTE,
  },
];
