import instance from "../axios";
import * as endpoints from "./endpoints";

import { AUTHORIZATION_KEY } from "global_constants";

export const getCampaignAdVideoFileUploadPath = (campaignId) => {
  return `${
    instance.defaults.baseURL
  }${endpoints.CAMPAIGN_AD_VIDEO_UPLOAD_API_PATH.replace("{}", campaignId)}`;
};

export const getCampaignAdImageFileUploadPath = (campaignId) => {
  return `${
    instance.defaults.baseURL
  }${endpoints.CAMPAIGN_AD_IMAGE_UPLOAD_API_PATH.replace("{}", campaignId)}`;
};

export const getCampaignPictureFileUploadPath = (campaignId) => {
  return `${
    instance.defaults.baseURL
  }${endpoints.CAMPAIGN_PICTURE_UPLOAD_API_PATH.replace("{}", campaignId)}`;
};

export const getSignature = (address) => {
  return instance.get(endpoints.SIGNATURE_API_PATH.replace("{}", address));
};

export const connect = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.CONNECT_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance
        .get(endpoints.USER_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const loginUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const registerUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.REGISTER_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgottenPassword = (payload) => {
  return instance.post(endpoints.FORGOT_PASSWORD_API_PATH, payload);
};

export const resetPassword = (payload) => {
  return instance.post(endpoints.RESET_PASSWORD_API_PATH, payload);
};

export const verifyEmail = (payload) => {
  return instance.post(endpoints.VERIFY_EMAIL_API_PATH, payload);
};

export const getCampaigns = () => {
  return instance.get(endpoints.CAMPAIGN_API_PATH);
};

export const getAllCampaignsEventStatistcs = (urlParamsString) => {
  return instance.get(
    endpoints.ALL_CAMPAIGN_EVENT_STATISTICS_API_PATH.concat(
      "?",
      urlParamsString
    )
  );
};

export const getAllCampaignsSpendStatistcs = (urlParamsString) => {
  return instance.get(
    endpoints.ALL_CAMPAIGN_SPEND_STATISTICS_API_PATH.concat(
      "?",
      urlParamsString
    )
  );
};

export const getCampaignDetail = (campaignId) => {
  return instance.get(
    endpoints.CAMPAIGN_DETAIL_API_PATH.replace("{}", campaignId)
  );
};

export const getCampaignEventStatistcs = (campaignId, urlParamsString) => {
  return instance.get(
    endpoints.CAMPAIGN_EVENT_STATISTICS_API_PATH.replace(
      "{}",
      campaignId
    ).concat("?", urlParamsString)
  );
};

export const getCampaignSpendStatistcs = (campaignId, urlParamsString) => {
  return instance.get(
    endpoints.CAMPAIGN_SPEND_STATISTICS_API_PATH.replace(
      "{}",
      campaignId
    ).concat("?", urlParamsString)
  );
};

export const createCampaign = (data) => {
  return instance.post(endpoints.CAMPAIGN_API_PATH, data);
};

export const updateCampaign = (data, campaignId) => {
  return instance.patch(
    endpoints.CAMPAIGN_DETAIL_API_PATH.replace("{}", campaignId),
    data
  );
};

export const createCampaignAd = (campaignId, data) => {
  return instance.post(
    endpoints.CAMPAIGN_AD_API_PATH.replace("{}", campaignId),
    data
  );
};

export const getCampaignAdDetail = (campaignId, adId) => {
  let url = endpoints.CAMPAIGN_AD_DETAIL_API_PATH.replace("{}", campaignId);
  url = url.replace("{adId}", adId);
  return instance.get(url);
};

export const editCampaignAd = (campaignId, adId, data) => {
  let url = endpoints.CAMPAIGN_AD_DETAIL_API_PATH.replace("{}", campaignId);
  url = url.replace("{adId}", adId);
  return instance.patch(url, data);
};

export const validateReferralDiscount = (campaignId, adId, data) => {
  let url = endpoints.CAMPAIGN_AD_VALIDATE_DISCOUNT_API_PATH.replace(
    "{}",
    campaignId
  );
  url = url.replace("{adId}", adId);
  return instance.post(url, data);
};
