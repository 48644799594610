export const AUTHORIZATION_KEY = "viewers_token";

export const NETWORK = {
  TARGET_CHAIN_ID: 97,
  MAINNET_CHAIN_ID: 1,
  CHAIN_ID_TO_NAME: {
    1: "mainnet",
    3: "ropsten",
    4: "rinkeby",
    42: "kovan",
    1337: "localhost",
    97: "bsctestnet",
  },
  INFURA_ID: "632b4850f79f4f7f9ba2468897bdcbc0",
};

export const CONTRACTS = {
  SERVERBRIDGE: "ViewsServerBridge",
};

export const NULL_ADDR = "0x0000000000000000000000000000000000000000";

export const VIDEO_CAMPAIGN_TYPE = "video";

export const CAMPAIGN_TYPES = [
  {
    title: "Video Ad",
    slug: VIDEO_CAMPAIGN_TYPE,
    description: `Show a short clip or a full video. All aspect ratios are supported.`,
  },
  {
    slug: "image",
    title: "Image Ad",
    description: `A single image or a whole carousel? We got you covered.`,
  },
];

export const PLACEMENT_TYPES = [
  {
    label: "Appear first",
    value: "top",
  },
  {
    label: "Appear to random intervals",
    value: "random",
  },
];

export const DEFAULT_FORM_VALIDATION_MESSAGES = {
  email: "Enter a valid email",
  required: "${label} is required!",
};

export const AD_EVENT_TYPE = {
  CLICK: "click",
  WATCHED: "watched",
  IMPRESSION: "impression",
};

export const AD_STATUS_TYPE = {
  PENDING: "pending",
};

export const PAYMENT_TOKENS = {
  BNB: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
  VIEWS: "0x22B1529B1568f6e99346EEC7af14cBed473F7c83",
};

export const DEFAULT_PAYMENT_TOKEN = "VIEWS";

export const LINKS = {
  TERMS: `https://www.views.money/terms/`,
  DISCORD: `https://discord.com/invite/d6uEq2gayC`,
  FEEDBACK: `https://9kav4uvxzld.typeform.com/to/yriy4XJk`,
  GITBOOK: `https://docs.views.money/about-views/stay-one-view-ahead`,
};