export const USER_API_PATH = "c/user/";

export const CONNECT_API_PATH = `${USER_API_PATH}connect/`;
export const SIGNATURE_API_PATH = `${USER_API_PATH}signature/?address={}`;

export const LOGIN_API_PATH = `${USER_API_PATH}sign_in/`;
export const REGISTER_API_PATH = `${USER_API_PATH}sign_up/`;
export const VERIFY_EMAIL_API_PATH = `${USER_API_PATH}verify_email/`;
export const FORGOT_PASSWORD_API_PATH = `${USER_API_PATH}forgotten_password/`;
export const RESET_PASSWORD_API_PATH = `${USER_API_PATH}change_forgotten_password/`;

export const CAMPAIGN_API_PATH = "campaigns/";
export const ALL_CAMPAIGN_SPEND_STATISTICS_API_PATH = `${CAMPAIGN_API_PATH}spend_statistics/`;
export const ALL_CAMPAIGN_EVENT_STATISTICS_API_PATH = `${CAMPAIGN_API_PATH}event_statistics/`;

export const CAMPAIGN_DETAIL_API_PATH = `${CAMPAIGN_API_PATH}{}/`;
export const CAMPAIGN_SPEND_STATISTICS_API_PATH = `${CAMPAIGN_DETAIL_API_PATH}spend_statistics/`;
export const CAMPAIGN_EVENT_STATISTICS_API_PATH = `${CAMPAIGN_DETAIL_API_PATH}event_statistics/`;

export const CAMPAIGN_PICTURE_UPLOAD_API_PATH = `${CAMPAIGN_DETAIL_API_PATH}upload_picture/`;
export const CAMPAIGN_AD_VIDEO_UPLOAD_API_PATH = `${CAMPAIGN_DETAIL_API_PATH}upload_video/`;
export const CAMPAIGN_AD_IMAGE_UPLOAD_API_PATH = `${CAMPAIGN_DETAIL_API_PATH}upload_image/`;

export const CAMPAIGN_AD_API_PATH = `${CAMPAIGN_DETAIL_API_PATH}ad/`;
export const CAMPAIGN_AD_DETAIL_API_PATH = `${CAMPAIGN_AD_API_PATH}{adId}/`;
export const CAMPAIGN_AD_VALIDATE_DISCOUNT_API_PATH = `${CAMPAIGN_AD_DETAIL_API_PATH}validate_referral_discount/`;
